<template>
  <Breadcrumb
    v-if="!String.isNullOrWhiteSpace(customPageData.name)"
    :detailTitle="customPageData.name.toUpperCase()"
  />
  <Breadcrumb v-else detailTitle="&nbsp;" />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-1">
          <div class="alert-info alert p-1 mb-1">
            <p class="mb-0">
              {{
                $t(
                  "BaseModelFields.SwitchFullScreenMode",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </p>
            <p class="mb-0">
              {{
                $t(
                  "BaseModelFields.SaveShortcut",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </p>
          </div>
          <form class="row g-3 form-customPage-edithtml" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="accordion" id="accordionPanelHtmlCssScript">
              <div class="accordion-item">
                <h2 class="accordion-header" id="html">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#html-body"
                    aria-expanded="true"
                    aria-controls="html"
                  >
                    {{
                      $t(
                        "CustomPage.HTML",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </h2>
                <div
                  id="html-body"
                  class="accordion-collapse collapse show"
                  aria-labelledby="html"
                >
                  <div class="accordion-body">
                    <v-ace-editor
                      v-model:value="html"
                      @init="editorInit"
                      lang="html"
                      theme="monokai"
                      id="htmlEditor"
                      class="h-250"
                      :options="options"
                    />
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="css">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#css-body"
                    aria-expanded="true"
                    aria-controls="css-body"
                  >
                    {{
                      $t(
                        "CustomPage.CSS",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </h2>
                <div
                  id="css-body"
                  class="accordion-collapse collapse show"
                  aria-labelledby="css"
                >
                  <div class="accordion-body">
                    <v-ace-editor
                      v-model:value="css"
                      lang="css"
                      theme="monokai"
                      @init="editorInit"
                      :options="options"
                      id="cssEditor"
                      class="h-250"
                    />
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="script">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#script-body"
                    aria-expanded="true"
                    aria-controls="script-body"
                  >
                    {{
                      $t(
                        "Dashboard.Script",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </h2>
                <div
                  id="script-body"
                  class="accordion-collapse collapse show"
                  aria-labelledby="script"
                >
                  <div class="accordion-body">
                    <v-ace-editor
                      v-model:value="script"
                      lang="javascript"
                      theme="monokai"
                      @init="editorInit"
                      :options="options"
                      id="scriptEditor"
                      class="h-250"
                    />
                  </div>
                </div>
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
  <ScriptHelperModal />
</template>
<script>
import $ from "jquery";

import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-monokai";

import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-css";

import "ace-builds/src-noconflict/ext-language_tools";
import beautifier from "ace-builds/src-noconflict/ext-beautify";

import "ace-builds/src-noconflict/snippets/html";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/snippets/css";

import "ace-builds/src-noconflict/ext-searchbox";

import ace from "ace-builds";
import workerHtmlUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-html.js";
ace.config.setModuleUrl("ace/mode/html_worker", workerHtmlUrl);

import workerJsUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-javascript.js";
ace.config.setModuleUrl("ace/mode/javascript_worker", workerJsUrl);

import workerCssUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-css.js";
ace.config.setModuleUrl("ace/mode/css_worker", workerCssUrl);

import { createToast } from "mosha-vue-toastify";

import ScriptHelperModal from "@/components/custom/script-helper/ScriptHelperModal.vue";

export default {
  name: "CustomPageEditHtmlScript",
  components: {
    VAceEditor,
    ScriptHelperModal,
  },
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      html: "",
      htmlEditor: null,
      script: "",
      scriptEditor: null,
      css: "",
      cssEditor: null,
      errors: [],
      customPageData: {},
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        wrap: true,
      },
      editorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
      },
    };
  },
  methods: {
    toggleFullScreen(type) {
      $("body").toggleClass("fullScreen");

      var editor = this.htmlEditor;
      if (type == "css") editor = this.cssEditor;
      else if (type == "script") editor = this.scriptEditor;
      else if (type != "html") return;

      this.$root.aceToggleFullScreen(editor);
    },
    getCustomPageHtmlScript() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CustomPageGet?id={0}",
            this.$route.params.customPageId
          )
        )
        .then((response) => {
          this.customPageData = response.data;
          if (!String.isNullOrWhiteSpace(response.data.html)) {
            this.html = response.data.html;
          }

          if (!String.isNullOrWhiteSpace(response.data.script)) {
            this.script = response.data.script;
          }

          if (!String.isNullOrWhiteSpace(response.data.css)) {
            this.css = response.data.css;
          }

          var self = this,
            saveCommand = {
              name: "Save",
              bindKey: { win: "Ctrl-S", mac: "Command-S" },
              exec: function (editor) {
                self.onSubmit("btn-save", false);
              },
              readOnly: false,
            };

          this.htmlEditor = ace.edit("htmlEditor");
          this.htmlEditor.commands.addCommand(
            this.$root.ace.commands.fullScreen
          );
          this.htmlEditor.commands.addCommand(saveCommand);

          this.scriptEditor = ace.edit("scriptEditor");
          this.scriptEditor.commands.addCommand(
            this.$root.ace.commands.fullScreen
          );
          this.scriptEditor.commands.addCommand(saveCommand);

          this.cssEditor = ace.edit("cssEditor");
          this.cssEditor.commands.addCommand(
            this.$root.ace.commands.fullScreen
          );
          this.cssEditor.commands.addCommand(saveCommand);

          beautifier.beautify(this.scriptEditor.session);
          beautifier.beautify(this.htmlEditor.session);
          beautifier.beautify(this.cssEditor.session);
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName, redirectToListPage = true) {
      var form = $(".form-customPage-edithtml");
      form.addClass("was-validated");
      if (!form[0].checkValidity()) {
        return;
      }

      beautifier.beautify(this.scriptEditor.session);
      beautifier.beautify(this.htmlEditor.session);
      beautifier.beautify(this.cssEditor.session);

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.customPageData.html = this.html.toString();
      this.customPageData.script = this.script.toString();
      this.customPageData.css = this.css.toString();

      this.$prodGatewayAxios
        .post("/Lcdp-CustomPageEditHtmlScriptCss", { ...this.customPageData })
        .then((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);

          var response = r.data;
          if (response.isOk) {
            if (redirectToListPage) {
              this.$router.push("/CustomPage/List");
            } else {
              createToast(
                this.$t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: "true",
                  position: "top-right",
                  type: "success",
                  transition: "zoom",
                }
              );
            }
          } else {
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getCustomPageHtmlScript();
  },
};
</script>
